import * as React from "react"
import PropTypes from "prop-types"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import MenuIcon from "@mui/icons-material/Menu"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { Link, useLocation } from "react-router-dom"
import { BoxStyled, AppBarStyled, ToolbarStyled, Image } from "./styles"

const BASE_URL = window.home_link()

const drawerWidth = 240
const navItems = [
    {
        label: "Demandez un prêt",
        url: BASE_URL,
        externalLink: true,
    },
    {
        label: "A propos d'Eqdom",
        url: "/a_propos_eqdom",
    },
    {
        label: "Espace Media",
        url: "/",
    },
]

function DrawerAppBar({ window }) {
    const [mobileOpen, setMobileOpen] = React.useState(false)
    const location = useLocation()

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                Eqdom
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.label} disablePadding>
                        <ListItemButton sx={{ textAlign: "center" }}>
                            {item.externalLink ? (
                                <a
                                    href={item.url}
                                    style={{
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        textDecoration: "none",
                                        display: "flex",
                                        color:
                                            location.pathname === item.url
                                                ? "rgb(222, 51, 42)"
                                                : "black",
                                    }}
                                >
                                    {item.label}
                                </a>
                            ) : (
                                <Link
                                    style={{
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        textDecoration: "none",
                                        display: "flex",
                                        color:
                                            location.pathname === item.url
                                                ? "rgb(222, 51, 42)"
                                                : "black",
                                    }}
                                    to={item.url}
                                >
                                    {item.label}
                                </Link>
                            )}
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    )

    const container =
        window !== undefined ? () => window().document.body : undefined

    return (
        <BoxStyled>
            <AppBarStyled component="nav">
                <ToolbarStyled>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <a href={BASE_URL}>
                        <Image
                            src={require("../../../assets/img/logo.png")}
                            alt="logo"
                        />
                    </a>
                    <Box sx={{ display: { xs: "none", sm: "block" } }}>
                        {navItems.map((item, index) => (
                            <Button key={item + index} sx={{ color: "black" }}>
                                {item.externalLink ? (
                                    <a
                                        href={item.url}
                                        style={{
                                            fontFamily: "Montserrat",
                                            fontSize: "14px",
                                            textDecoration: "none",
                                            display: "flex",
                                            color:
                                                location.pathname === item.url
                                                    ? "rgb(222, 51, 42)"
                                                    : "black",
                                        }}
                                    >
                                        {item.label}
                                    </a>
                                ) : (
                                    <Link
                                        style={{
                                            fontFamily: "Montserrat",
                                            fontSize: "14px",
                                            textDecoration: "none",
                                            display: "flex",
                                            color:
                                                location.pathname === item.url
                                                    ? "rgb(222, 51, 42)"
                                                    : "black",
                                        }}
                                        to={item.url}
                                    >
                                        {item.label}
                                    </Link>
                                )}
                            </Button>
                        ))}
                    </Box>
                </ToolbarStyled>
            </AppBarStyled>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </BoxStyled>
    )
}

DrawerAppBar.propTypes = {
    window: PropTypes.func,
}

export default DrawerAppBar
