import {
    Container,
    SubTitle,
    Section,
    BlackSubTitle,
    RedSpan,
    DateSpan,
} from "./styles"

const AboutContent = () => {
    return (
        <Container>
            <Section>
                <SubTitle>Avec vous chaque jour…</SubTitle>
                <BlackSubTitle>
                    Innovation, esprit d’équipe, professionnalisme :
                </BlackSubTitle>
                <ul>
                    <li>
                        <p>
                            <RedSpan>4 valeurs</RedSpan> que nous mettons au
                            service de nos clients depuis près de 40 ans ;
                        </p>
                    </li>
                    <li>
                        <p>
                            <RedSpan>4 valeurs</RedSpan> qui sont celles du
                            groupe Société Générale dont nous sommes filiale
                            depuis 2002. Vous voulez en savoir plus ? Un peu
                            d’histoire…
                        </p>
                    </li>
                </ul>
            </Section>
            <Section>
                <SubTitle>Plus de 40 ans à votre service.</SubTitle>
                <RedSpan>Eqdom</RedSpan>, pour « Equipement domestique », est la
                première société de crédit créée par l’Etat pour les
                fonctionnaires afin d’accompagner leurs achats de biens
                d’équipements. .. Avec des premiers résultats très favorables,
                l’entreprise choisit rapidement de diversifier son activité en
                proposant ses services aux particuliers et en élargissant son
                offre : elle devient ainsi leader sur un produit phare, le prêt
                personnel ou « dispocrédit » en langage <RedSpan>Eqdom</RedSpan>
                . Preuve de ces succès,
                <RedSpan> Eqdom </RedSpan>
                est introduite à la Bourse de Casablanca en 1978.
            </Section>
            <Section>
                <SubTitle>Une entreprise en croissance</SubTitle>
                <span>
                    Avec son rachat en 2002 par la Société Générale,
                    <RedSpan> Eqdom </RedSpan> a montré son potentiel de
                    développement. En appartenant désormais à un groupe
                    international et puissant ,<RedSpan> Eqdom </RedSpan> répond
                    à ses exigences du professionnalisme des équipes, d’un suivi
                    des risques rigoureux et de transparence dans la publication
                    de nos résultats.
                </span>
            </Section>
            <Section>
                <SubTitle>Dates clés</SubTitle>
                <ul>
                    <li>
                        <p>
                            <DateSpan>1995</DateSpan>: L’Etat actionnaire vend
                            sa participation à l’ONA (18% du capital)
                        </p>
                    </li>
                    <li>
                        <p>
                            <DateSpan>1997</DateSpan>: 1ère émission de BSF :
                            350 millions de DHR
                        </p>
                    </li>
                    <li>
                        <p>
                            <DateSpan>2002</DateSpan>: L’ONA cède au groupe
                            Société Générale 44,42% du capital. La même année,
                            la Société Générale lance une OPA partielle sur 8%
                            du Capital
                        </p>
                    </li>
                </ul>
                <span>
                    Aujourd’hui,
                    <RedSpan> Eqdom </RedSpan>
                    dispose d’un large réseau de distribution pour proposer à
                    ses clients sa gamme de produits, que vous découvrez dans ce
                    site, avec une attention particulière apportée à la qualité
                    de service.
                </span>
            </Section>
        </Container>
    )
}

export default AboutContent
