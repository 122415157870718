import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import DrawerAppBar from "../components/common/navbar/drawerAppBar"
import PageTitle from "../components/common/pageTitle/pageTitle"
import Footer from "../components/common/footer/footer"
import SidebarLinks from "../components/common/sidebarLinks/sidebarLinks"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Container from "@mui/material/Container"
import GeneralConditions from "../components/common/generalConditions/generalConditions"
import MentionsLegales from "../components/common/mentionsLegales/mentionsLegales"
import useWindowDimensions from "../hooks/useWindowDimensions"

const sidebarLinks = [
    // {
    //     label: "Conditions générales d'utilisation",
    //     value: 1,
    // },
    {
        label: "Mentions légales",
        value: 2,
    },
]

const DONNEE_PERSO = window.donnee_perso()

const About = () => {
    const { id } = useParams()
    const { width } = useWindowDimensions()
    const [currentSection, setCurrentSection] = useState(
        parseInt(id) !== 0 ? parseInt(id) : 1
    )
    const { t } = useTranslation()

    const handleOnClickSideLinks = (value) => {
        setCurrentSection(value)
    }

    const renderPageTitle = () => {
        switch (currentSection) {
            case 1:
                return t("general_conditions_title")
            case 2:
                return t("mentions_legales_title")
            default:
                return null
        }
    }

    return (
        <>
            <div style={{ position: "relative", minHeight: "100vh" }}>
                <DrawerAppBar />

                <Container
                    maxWidth="lg"
                    {...(width < 600
                        ? { sx: { paddingBottom: "20em" } }
                        : { sx: { paddingBottom: "10em" } })}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        {/* Title Section */}
                        <Grid md={12}>
                            <PageTitle title={t("otherPageTitle")} />
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <SidebarLinks
                                    links={sidebarLinks}
                                    currentUuid={currentSection}
                                    handleOnClick={handleOnClickSideLinks}
                                />
                            </Grid>

                            <Grid item md={8}>
                                <Box sx={{ width: "100%" }}>
                                    <h1
                                        style={{
                                            marginTop: 0,
                                            fontWeight: 400,
                                        }}
                                    >
                                        {renderPageTitle()}
                                    </h1>
                                    {currentSection === 1 ? (
                                         <GeneralConditions />
                                        
                                    ) : (
                                        <MentionsLegales />
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>

                <Footer />
            </div>
        </>
    )
}

export default About
