import styled from "styled-components"
import { Button } from "@mui/material"

export const ButtonPrimary = styled(Button)`
    background-color: #de332a !important;
    color: white;
    border: 0;
    height: 36px;
    border-radius: 5px;
    padding: 2px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    text-align: center;
`

export const ButtonSecondary = styled(Button)`
    border-color: #de332a !important;
    color: #de332a !important;
    border: 0;
    height: 36px;
    border-radius: 5px;
    padding: 2px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    text-align: center;
`
