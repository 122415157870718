/* eslint-disable import/no-anonymous-default-export */
const initialState = {
    loading: false,
    listDocuments: [],
    categoriesPublication: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "GET_ALL_DOCUMENTS":
            return {
                ...state,
                loading: true,
                listDocuments: [],
            }
        case "GET_ALL_DOCUMENTS_SUCCESS":
            return {
                ...state,
                loading: false,
                listDocuments: action.payload.documents,
            }
        case "GET_ALL_DOCUMENTS_FAILED":
            return {
                ...state,
                loading: false,
                listDocuments: [],
            }
        case "GET_CATEGORIE_PUBLICATION":
            return {
                ...state,
                loading: true,
                categoriesPublication: [],
            }
        case "GET_CATEGORIE_PUBLICATION_SUCCESS":
            return {
                ...state,
                loading: false,
                categoriesPublication: action.payload.categoriesPublication,
            }
        case "GET_CATEGORIE_PUBLICATION_FAILED":
            return {
                ...state,
                loading: false,
                categoriesPublication: [],
            }
        default:
            return state
    }
}
