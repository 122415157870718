import React from "react"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import { Iframe } from "./styles"

const BoxXsStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 0,
}

export default function BasicModal({ open, setOpen, link }) {
    return (
        <div>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={BoxXsStyle}>
                    <Iframe src={link} title="PDF"></Iframe>
                </Box>
            </Modal>
        </div>
    )
}
