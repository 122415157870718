import styled from "styled-components"

export const OpenModalButton = styled.button`
    float: right;
    width: 30px;
    background-color: #de332a;
    display: block;
    color: white;
    border: 0;
    border-radius: 5px;
    margin: 10px 0;
    padding: 2px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    text-align: center;
    cursor: pointer;
`

export const InfoButton = styled.button`
    background-color: #de332a;
    display: flex;
    color: white;
    border: 0;
    border-radius: 5px;
    margin: 10px 0;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
`

export const InfoButtonText = styled.span`
    align-self: center;
`

export const RowTitle = styled.span`
    font-size: 18px;
    font-family: inherit;
`
