import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import BasicTable from "../components/common/basicTable/basicTable"
import Filters from "../components/common/filters/filters"
import BasicModal from "../components/common/basicModal/basicModal"
import DrawerAppBar from "../components/common/navbar/drawerAppBar"
import PageTitle from "../components/common/pageTitle/pageTitle"
import Footer from "../components/common/footer/footer"
import SidebarLinks from "../components/common/sidebarLinks/sidebarLinks"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Container from "@mui/material/Container"
import useWindowDimensions from "../hooks/useWindowDimensions"
import {
    getPublicationByPageId,
    getCategoriePublicationByUuidPage,
} from "../services/actions/document"
import {
    useDispatchDocument,
    useDocument,
} from "../services/providers/DocumentProvider"
import { useParams } from "react-router-dom"

function createData(name, link, description) {
    return {
        name,
        link,
        description,
    }
}

const rows_load = [createData("Loading .....", "#", "No data !")]

function formatFilterData(name, value) {
    return {
        name,
        value,
    }
}

const sidebarLinks = [
    // {
    //     label: "Actualités",
    //     value: "P003",
    // },
    {
        label: "Publications",
        value: "P001",
    },
    {
        label: "Communiqués de presse",
        value: "P002",
    },
]

const MediaSpace = () => {
    const { id } = useParams()
    const { t } = useTranslation()
    const { dispatch } = useDispatchDocument()
    const { documentState } = useDocument()
    const [docsData, setDocsData] = useState([])
    const [currentUuid, setCurrentUuid] = useState("")
    const [open, setOpen] = useState(false)
    const [categorie, setCategorie] = useState("")
    const [pdfLink, setPdfLink] = useState("")
    const { width } = useWindowDimensions()
    const [categoriesData, setCategoriesData] = useState([
        { name: "No data", value: null },
    ])

    const { loading, listDocuments, categoriesPublication } = documentState

    useEffect(() => {
        let value
        switch (id) {
            case "1":
                value = "P001"
                setCurrentUuid(value)
                getCategoriePublicationByUuidPage({ dispatch, uuidPage: value })
                getPublicationByPageId({
                    dispatch,
                    uuidPage: value,
                })
                break
            case "2":
                value = "P002"
                setCurrentUuid(value)
                getCategoriePublicationByUuidPage({ dispatch, uuidPage: value })
                getPublicationByPageId({
                    dispatch,
                    uuidPage: value,
                })
                break

            default:
                getPublicationByPageId({ dispatch, currentUuid })
                break
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (listDocuments?.length > 0) {
            const tempData = listDocuments?.map((elm) =>
                createData(elm.name, elm.urlDocument, elm.description)
            )
            setDocsData(tempData)
        } else {
            setDocsData([])
        }
    }, [listDocuments])

    useEffect(() => {
        if (categoriesPublication?.length > 0) {
            const tempData = categoriesPublication?.map((elm) =>
                formatFilterData(elm.name, elm.uuid)
            )
            tempData.unshift({ name: "Tout", value: "" })
            setCategoriesData(tempData)
        } else if (categoriesPublication?.length === 0) {
            setCategoriesData([{ name: "No data", value: null }])
        }
    }, [categoriesPublication])

    const handleKeywordChange = (value) => {
        getPublicationByPageId({
            dispatch,
            uuidPage: currentUuid,
            uuidCategory: categorie,
            motCle: value,
        })
    }

    const handleCategorieChange = (value) => {
        setCategorie(value)
        getPublicationByPageId({
            dispatch,
            uuidPage: currentUuid,
            uuidCategory: value,
        })
    }

    const handleReset = () => {
        setCategorie("")
        getPublicationByPageId({
            dispatch,
            uuidPage: currentUuid,
        })
    }

    const handleOpen = (link) => {
        setPdfLink(link)
        setOpen(true)
    }

    const handleOnClickSideLinks = (value) => {
        setCategorie("")
        setCurrentUuid(value)
        getCategoriePublicationByUuidPage({ dispatch, uuidPage: value })
        getPublicationByPageId({
            dispatch,
            uuidPage: value,
        })
    }

    const renderPageTitle = () => {
        switch (currentUuid) {
            case "P001":
                return t("publications_title")
            case "P002":
                return t("com_presse_title")
            case "P003":
                return t("actualites_title")
            default:
                return null
        }
    }

    return (
        <>
            <div style={{ position: "relative", minHeight: "100vh" }}>
                <DrawerAppBar />

                <Container
                    maxWidth="lg"
                    {...(width < 600
                        ? { sx: { paddingBottom: "20em" } }
                        : { sx: { paddingBottom: "10em" } })}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        {/* Title Section */}
                        <Grid item md={12}>
                            <PageTitle title={t("mediaPageTitle")} />
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <SidebarLinks
                                    links={sidebarLinks}
                                    currentUuid={currentUuid}
                                    handleOnClick={handleOnClickSideLinks}
                                />
                            </Grid>

                            <Grid item md={8}>
                                <Box sx={{ width: "100%" }}>
                                    <h1
                                        style={{
                                            marginTop: 0,
                                            fontWeight: 400,
                                        }}
                                    >
                                        {renderPageTitle()}
                                    </h1>
                                    {/* pdf Modal */}
                                    <BasicModal
                                        open={open}
                                        setOpen={setOpen}
                                        link={pdfLink}
                                    />
                                    {categoriesData && (
                                        <Filters
                                            data={categoriesData}
                                            categorie={categorie}
                                            handleCategorieChange={
                                                handleCategorieChange
                                            }
                                            handleKeywordChange={
                                                handleKeywordChange
                                            }
                                            handleReset={handleReset}
                                        />
                                    )}
                                    {/* Main Table */}
                                    {loading && docsData.length > 0 ? (
                                        <BasicTable rows={rows_load} />
                                    ) : (
                                        <BasicTable
                                            rows={docsData}
                                            handleOpenModal={handleOpen}
                                        />
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>

                <Footer />
            </div>
        </>
    )
}

export default MediaSpace
