import axios from "axios"
// import i18n from "../../i18n/i18n";

const BASE_URL = window.global_conf()

export const getPublicationByPageId = async ({
    dispatch,
    uuidPage = "",
    uuidCategory = "",
    motCle = "",
}) => {
    try {
        const headers = {
            Accept: "application/json",
        }

        const dev = {
            url: `${BASE_URL}/publication/list?sort=id%2Casc&uuidPage=${uuidPage}&uuidCategorie=${uuidCategory}&motCle=${motCle}&size=300`,
            method: "get",
            data: {},
            headers,
        }

        await dispatch({
            type: "GET_ALL_DOCUMENTS",
        })

        const { data, status } = await axios(dev)

        if (status === 200) {
            const { publications } = data
            await dispatch({
                type: "GET_ALL_DOCUMENTS_SUCCESS",
                payload: { documents: publications },
            })
        }
    } catch (error) {
        await dispatch({
            type: "GET_ALL_DOCUMENTS_FAILED",
        })
        console.log("Error while fetching docs data")
    }
}

export const getCategoriePublicationByUuidPage = async ({
    dispatch,
    uuidPage,
}) => {
    try {
        const headers = {
            Accept: "application/json",
        }

        const dev = {
            url: `${BASE_URL}/referentiels/categorie-publication-uuid-page/${uuidPage}`,
            method: "get",
            data: {},
            headers,
        }

        await dispatch({
            type: "GET_CATEGORIE_PUBLICATION",
        })

        const { data, status } = await axios(dev)

        if (status === 200) {
            await dispatch({
                type: "GET_CATEGORIE_PUBLICATION_SUCCESS",
                payload: { categoriesPublication: data },
            })
        }
    } catch (error) {
        await dispatch({
            type: "GET_CATEGORIE_PUBLICATION_FAILED",
        })
        console.log("Error while fetching categories data")
    }
}
