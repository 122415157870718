import { Container, Section, RedSpan, SubTitle } from "./styles"

const MentionsLegales = () => {
    return (
        <Container>
            <Section>
                Vous êtes en train de visiter le site de EQDOM Les mentions
                légales qui suivent s’appliquent à tout internaute visitant ce
                site. Nous vous remercions d’en prendre connaissance et de vous
                y conformer.Le site <RedSpan>www.eqdomnet.ma</RedSpan> est la
                propriété de EQDOM, Société Anonyme, au capital de 167 025
                000,00 DHS, dont le siège social est à Casablanca, au 127,
                Boulevard Zerktouni.
            </Section>
            <Section>
                Ce Site a été créé par EQDOM{" "}
                <RedSpan>(webmaster@eqdom.co.ma)</RedSpan> et les informations
                regroupées dans ce Site sont destinées à une présentation
                institutionnelle de nos activités. EQDOM se réserve le droit de
                modifier le contenu du Site à tout moment et sans préavis et ne
                pourra être tenue pour responsable des conséquences de telles
                modifications. L’accès et l’utilisation de ce Site sont soumis
                aux conditions suivantes ainsi qu’aux dispositions légales en
                vigueur. En accédant au Site, vous acceptez, sans limitation ni
                réserves, ces conditions.
            </Section>
            <Section>
                <SubTitle>Juridiction</SubTitle>
                <span>
                    Ce site et son contenu sont créés conformément aux règles
                    applicables au Maroc, même s’ils sont accessibles à d’autres
                    utilisateurs.
                </span>
            </Section>
            <Section>
                <SubTitle>Contenus du Site</SubTitle>
                <p>
                    EQDOM fournit les informations contenues dans ce site à des
                    fins purement informatives et s’efforce de contrôler la
                    véracité des informations et de maintenir son site à jour.
                    Cependant, aucune garantie n’est requise concernant
                    l’exactitude, la précision, la mise à jour ou l’exhaustivité
                    des informations mises à la disposition sur le Site.
                </p>
                <p>
                    Les simulations effectuées en ligne sur la partie «
                    Simulateur de crédit » ne sont en aucun cas contractuelles.
                    Seul un chargé de clientèle dans une agence Eqdom peut
                    apprécier la validité de votre demande de crédit, avec les
                    pièces justificatives fournies.
                </p>
                <p>
                    Par conséquent, EQDOM décline toute responsabilité pour tout
                    dommage résultant notamment d’une interprétation,
                    imprécision ou inexactitude des informations disponibles sur
                    notre Site ou pour toute atteinte résultant d’une intrusion
                    frauduleuse d’un tiers sur notre Site avec l’intention de
                    nuire aux intérêts ou à l’image de EQDOM, notamment en
                    dénaturant les informations répertoriées sur le Site. La
                    responsabilité de EQDOM ne saurait être retenue pour tout
                    dommage ou virus qui pourrait endommager ou rendre
                    inutilisable votre équipement informatique suite à la visite
                    de notre site malgré les actions de sécurisation mises en
                    œuvre.
                </p>
            </Section>
            <Section>
                <SubTitle>Conditions générales d’utilisation</SubTitle>
                <p>
                    L’utilisateur reconnaît avoir pris connaissance des
                    présentes conditions générales d’utilisation et s’engage à
                    les respecter.
                </p>
                <p>
                    EQDOM se réserve le droit de modifier la notice relative aux
                    mentions légales objet des présentes à tout moment.
                </p>
                <p>
                    L'utilisateur s'engage donc à la consulter régulièrement
                    afin de s’informer sur les éventuelles mises à jour portées
                    à ce document.
                </p>
                <p>
                    Dans l'hypothèse où vous accéderiez au site{" "}
                    <RedSpan>www.eqdomnet.ma</RedSpan>à partir d'un autre pays
                    que le Maroc, vous vous engagez à vous assurer également du
                    respect de la législation localement applicable.
                </p>
            </Section>
            <Section>
                <SubTitle>Liens hypertexte</SubTitle>
                <p>
                    Afin de faciliter l’accès à d’autres sites susceptibles
                    d’apporter un complément d’information, EQDOM a inséré dans
                    son site un certain nombre de liens hypertexte. Néanmoins,
                    la responsabilité de EQDOM ne saurait être engagée au titre
                    d’un site tiers auquel vous auriez eu accès via notre Site
                    et présentant des contenus litigieux ou inexacts. EQDOM ne
                    dispose d’aucun moyen de contrôle des contenus des sites
                    tiers. Des sites externes peuvent contenir des liens
                    hypertextes pointant vers notre Site, EQDOM n’a aucun moyen
                    légal de s’opposer à cette pratique usitée dans Internet et
                    ne saurait en aucun cas être tenu pour responsable de
                    contenus issus de sites externes à EQDOM.
                </p>
            </Section>
            <Section>
                <SubTitle>Droits d’auteur</SubTitle>
                <p>
                    Toutes les images, marques, logos, noms de domaine et
                    information sous forme de texte ou d’image présents sur le
                    site sont la propriété de EQDOM ou font l’objet d’une
                    autorisation d’utilisation. Toute utilisation par
                    reproduction, modification, téléchargement, transmission ou
                    autre procédé existant présent ou futur est interdite.
                </p>
                <p>
                    Seules les copies à usage privé et non-commercial sont
                    autorisées sous réserve des droits de propriété
                    intellectuelle dont il est fait mention. La reprise
                    d’articles ou de reportages présents dans ce site est
                    également interdite. Tout contrevenant s’expose à des
                    poursuites judiciaires.
                </p>
            </Section>
            <Section>
                <SubTitle>Crédits photographiques</SubTitle>
                <p>
                    Toute reproduction ou utilisation de documents
                    photographiques dont EQDOM possède le copyright est
                    interdite quel que soit le type de publication.
                </p>
                <p>
                    Aucune photographie ne peut être utilisée à des fins
                    publicitaires sans autorisation.
                </p>
                <p>
                    Si vous effectuez une manipulation sur l’image vous créez
                    une oeuvre "dérivée". Le créateur de l’œuvre originale est
                    considéré comme propriétaire des droits d’auteur. En
                    conséquence, vous n’êtes pas détenteur des droits d’auteur
                    de l’œuvre dérivée dans la mesure où vous n’êtes pas
                    propriétaire de l’œuvre originale
                </p>
            </Section>
            <Section>
                <SubTitle>Marques</SubTitle>
                <p>
                    Les produits et marques de EQDOM sont des marques de
                    commerce déposées au Maroc. Les noms de la totalité des
                    autres produits, services ou sociétés éventuels mentionnés
                    sur le présent site peuvent être des marques de commerce de
                    leurs détenteurs respectifs.
                </p>
                <p>
                    Il vous est interdit d'utiliser la moindre marque de
                    commerce figurant sur le présent site.
                </p>
            </Section>
        </Container>
    )
}

export default MentionsLegales
