/* eslint-disable import/no-anonymous-default-export */
import { createContext, useContext, useReducer } from "react"
import DocumentReducer from "../reducers/DocumentReducer"

const DocumentContext = createContext()
const DocumentDispatchContext = createContext()

export default ({ children }) => {
    const [documentState, dispatch] = useReducer(DocumentReducer, {})

    return (
        <DocumentDispatchContext.Provider
            value={{
                dispatch
            }}
        >
            <DocumentContext.Provider
                value={{
                    documentState
                }}
            >
                {children}
            </DocumentContext.Provider>
        </DocumentDispatchContext.Provider>
    )
}

export const useDocument = () => useContext(DocumentContext)
export const useDispatchDocument = () => useContext(DocumentDispatchContext)