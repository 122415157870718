import { Container, Section, RedSpan, Span } from "./styles"
const DONNEE_PERSO = window.donnee_perso()
const GeneralConditions = () => {
     window.open(DONNEE_PERSO, '_blank')
    return (
        <Container>
            <Section>
                <Span>Eqdom </Span>est le responsable de traitement qui exploite
                le site <RedSpan>www.eqdom.ma</RedSpan>.
            </Section>
            <Section>
                <span>
                    Les informations recueillies sur le site{" "}
                    <RedSpan>www.eqdom.ma</RedSpan> font l’objet d’un traitement
                    destiné à la gestion des crédits et des garanties.
                </span>
            </Section>
            <Section>
                <span>
                    Les destinataires des données sont les personnes habilités
                    qui connaissent leurs obligations légales en matière de
                    protection des personnes physiques à l'égard du traitement
                    des données à caractère personnel.
                </span>
            </Section>
            <Section>
                <span>
                    Conformément à la loi n° 09-08 promulguée par le Dahir
                    1-09-15 du 18 février 2009, relative à la protection des
                    personnes physiques à l'égard du traitement des données à
                    caractère personnel, vous bénéficiez d’un droit d’accès et
                    de rectification aux informations qui vous concernent, que
                    vous pouvez exercer en vous adressant au SERVICE QUALITE
                    CLIENT <RedSpan>servicequalite@eqdom.co.ma</RedSpan>
                </span>
            </Section>
            <Section>
                <span>
                    Vous pouvez également, pour des motifs légitimes, vous
                    opposer à ce que les données qui vous concernent fassent
                    l’objet d’un traitement.
                </span>
                <span>
                    Ce traitement a été notifié et autorisé par la CNDP au titre
                    du récépissé / de l’autorisation{" "}
                    <RedSpan>n° A-GC-172/2014</RedSpan>.
                </span>
            </Section>
            <Section>
                <span>
                    Par le biais de ce formulaire, EQDOM collecte vos données
                    personnelles en vue d’un traitement destiné à la gestion des
                    crédits et des garanties. Ce traitement a fait l’objet d’une
                    déclaration / demande d’autorisation auprès de la CNDP sous
                    le numéro <RedSpan>A-GC-172/2014</RedSpan> Les données
                    personnelles collectées à la société mère conformément à la
                    demande de transfert déposée auprès de la CNDP, à ses
                    sous-traitants, aux autorités compétentes ou de tutelle, au
                    service de centralisation des risques, y compris les
                    compagnies et courtiers d’assurances.
                </span>
            </Section>
            <Section>
                <span>
                    Les utilisateurs sont informés que lors de leurs passage sur
                    notre site <RedSpan>www.eqdom.ma</RedSpan>, un cookie peut
                    s'installer automatiquement sur leurs logiciels de
                    navigation. L'objectif est d' analyser l'utilisation du site
                    Eqdom pour :
                </span>
                <ul>
                    <li>
                        <span>
                            Vous envoyer des informations liées à votre demande
                            de crédit en ligne
                        </span>
                    </li>
                    <li>
                        <span>
                            vous faire part d'informations ou offres
                            commerciales concernant des produits proposés par
                            Eqdom.
                        </span>
                    </li>
                </ul>
                <span>
                    Si vous ne souhaitez plus recevoir d’information ou d’offres
                    promotionnelles d’Eqdom, vous avez la possibilité de vous
                    désabonner en contactant le SERVICE QUALITE CLIENT au
                    <RedSpan> servicequalite@eqdom.co.ma</RedSpan>
                </span>
            </Section>
        </Container>
    )
}

export default GeneralConditions
