import React, { useState } from "react"
import {
    Select,
    TextField,
    FormControl,
    MenuItem,
    InputLabel,
    Box,
} from "@mui/material"
import { ButtonPrimary, ButtonSecondary } from "./styles"

const BoxXsStyle = {
    minWidth: 120,
    backgroundColor: "#fafafa",
    boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    borderTopLeftRadius: "4px",
    borderToprightRadius: "4px",
}

function Filters({
    data,
    categorie,
    handleCategorieChange,
    handleKeywordChange,
    handleReset,
}) {
    const [searchText, setSearchText] = useState("")

    const handleResetFilter = () => {
        setSearchText("")
        handleReset()
    }

    return (
        <Box sx={BoxXsStyle}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-simple-select-label">Categorie</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={categorie}
                    label="Categorie"
                    onChange={(event) =>
                        handleCategorieChange(event.target.value)
                    }
                >
                    {data?.map((elm, idx) => (
                        <MenuItem
                            value={elm.value}
                            key={idx}
                            {...(elm.value == null ? { disabled: true } : {})}
                        >
                            {elm.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
                <TextField
                    id="outlined-basic"
                    label="Mots Cles / tag"
                    variant="outlined"
                    size="small"
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                />
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 100 }}>
                <ButtonPrimary
                    onClick={() => handleKeywordChange(searchText)}
                    variant="contained"
                >
                    Filtrer
                </ButtonPrimary>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 100 }}>
                <ButtonSecondary onClick={handleResetFilter} variant="outlined">
                    Annuler
                </ButtonSecondary>
            </FormControl>
        </Box>
    )
}

export default Filters
