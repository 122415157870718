import Box from "@mui/material/Box"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import styled from "styled-components"

export const BoxStyled = styled(Box)`
    display: flex;
    height: 80px;
`

export const AppBarStyled = styled(AppBar)`
    background-color: white !important;
    color: black !important;
`

export const ToolbarStyled = styled(Toolbar)`
    display: flex;
    justify-content: space-between;
`

export const Image = styled.img`
    width: 100px;
`
