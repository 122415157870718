import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import DrawerAppBar from "../components/common/navbar/drawerAppBar"
import PageTitle from "../components/common/pageTitle/pageTitle"
import Footer from "../components/common/footer/footer"
import SidebarLinks from "../components/common/sidebarLinks/sidebarLinks"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Container from "@mui/material/Container"
import AboutContent from "../components/common/aboutContent/aboutContent"
import useWindowDimensions from "../hooks/useWindowDimensions"

const sidebarLinks = [
    {
        label: "Eqdom",
        value: 1,
    },
    {
        label: "Administrateurs",
        value: 2,
    },
]

const About = () => {
    const [currentSection, setCurrentSection] = useState(1)
    const { t } = useTranslation()
    const { width } = useWindowDimensions()

    const handleOnClickSideLinks = (value) => {
        setCurrentSection(value)
    }

    const renderPageTitle = () => {
        switch (currentSection) {
            case 1:
                return t("a_propos_title")
            case 2:
                return t("actionnariat_title")
            default:
                return null
        }
    }

    return (
        <>
            <div style={{ position: "relative", minHeight: "100vh" }}>
                <DrawerAppBar />

                <Container
                    maxWidth="lg"
                    {...(width < 600
                        ? { sx: { paddingBottom: "20em" } }
                        : { sx: { paddingBottom: "10em" } })}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        {/* Title Section */}
                        <Grid item md={12}>
                            <PageTitle title={t("aboutPageTitle")} />
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <SidebarLinks
                                    links={sidebarLinks}
                                    currentUuid={currentSection}
                                    handleOnClick={handleOnClickSideLinks}
                                />
                            </Grid>

                            <Grid item md={8}>
                                <Box sx={{ width: "100%" }}>
                                    <h1
                                        style={{
                                            marginTop: 0,
                                            fontWeight: 400,
                                        }}
                                    >
                                        {renderPageTitle()}
                                    </h1>
                                    {currentSection === 1 ? (
                                        <AboutContent />
                                    ) : (
                                        <img
                                            style={{
                                                maxWidth: "100%",
                                                height: "auto",
                                                padding: 0,
                                                margin: 0,
                                            }}
                                            src={require("../assets/img/liste_administrateur.png")}
                                            alt="logo"
                                        />
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>

                <Footer />
            </div>
        </>
    )
}

export default About
