import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    line-height: 24px;
`

export const Section = styled.div`
    margin-top: 30px;
`

export const SubTitle = styled.h3`
    color: black;
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 18px;
    font-family: Montserrat;
`
export const RedSpan = styled.span`
    color: rgb(222, 51, 42);
`
