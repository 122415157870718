import PropTypes from "prop-types"
import useWindowDimensions from "../../../hooks/useWindowDimensions"

import {
    Container,
    LineSeparator,
    Title,
    TitleMobile,
    ForwardIconStyled,
} from "./styles"

export default function PageTitle({ title }) {
    const { width } = useWindowDimensions()

    return (
        <Container>
            <ForwardIconStyled />
            {width > 758 ? (
                <>
                    <Title>{title}</Title>
                    <LineSeparator />
                </>
            ) : (
                <TitleMobile>{title}</TitleMobile>
            )}
        </Container>
    )
}

PageTitle.propTypes = {
    title: PropTypes.string,
}
