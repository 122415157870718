import "./i18n/i18n"
import { Route, Routes, BrowserRouter as Router } from "react-router-dom"
import MediaSpace from "./pages/MediaSpace"
import About from "./pages/About"
import Other from "./pages/Other"

function Navigation() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<MediaSpace />} />
                <Route path="/espace_media" element={<MediaSpace />} />
                <Route path="/espace_media/:id" element={<MediaSpace />} />
                <Route path="/a_propos_eqdom" element={<About />} />
                <Route path="/autres_acces/:id" element={<Other />} />
            </Routes>
        </Router>
    )
}

export default Navigation
