import styled from "styled-components"
import ForwardIcon from "@mui/icons-material/Forward"

export const Container = styled.div`
    display: flex;
    font-size: 40px;
    font-family: Montserrat;
    margin: 20px 0;
`

export const LineSeparator = styled.hr`
    width: 100%;
    border-bottom: 1px solid gray;
    border-top: 0;
    border-right: 0;
    border-left: 0;
`

export const Title = styled.div`
    margin-left: 10px;
    width: 45%;
    font-family: Montserratmedium;
    font-size: 30px;
    font-weight: 600;
`

export const TitleMobile = styled.div`
    margin-left: 10px;
    width: 100%;
    font-family: Montserratmedium;
    font-weight: 600;
`

export const ForwardIconStyled = styled(ForwardIcon)`
    align-self: center;
    color: rgb(222, 51, 42);
`
