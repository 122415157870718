import Container from "@mui/material/Container"
import { useTranslation } from "react-i18next"
import {
    FooterContainer,
    FlexContainer,
    Image,
    Copyright,
    List,
    LeftContainer,
} from "./styles"

const DONNEE_PERSO = window.donnee_perso()
const CONDITION_GENERALES=window.conditions_generales()

function Footer() {
    const { t } = useTranslation()
    return (
        <FooterContainer>
            <Container maxWidth="lg" style={{ height: "100%" }}>
                <FlexContainer>
                    <LeftContainer>
                        <Image
                            src={require("../../../assets/img/logo_white.png")}
                            alt="logo"
                        />
                        <Copyright>© EQDOM 2022,Tous droits réservés</Copyright>
                    </LeftContainer>
                    <div>
                        <List>
                            {/* <li>
                                <a
                                    style={{
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        textDecoration: "none",
                                        display: "flex",
                                    }}
                                    href={DONNEE_PERSO}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t("donnees_perso_title")}
                                </a>
                            </li>
                            <li>
                                <a
                                    style={{
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        textDecoration: "none",
                                        display: "flex",
                                    }}
                                    href={CONDITION_GENERALES}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t("general_conditions_title")}
                                </a>
                            </li> */}
                            <li>
                                <a
                                    style={{
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        textDecoration: "none",
                                        display: "flex",
                                    }}
                                    href={"/autres_acces/2"}
                                >
                                    {t("mentions_legales_title")}
                                </a>
                            </li>
                        </List>
                    </div>
                </FlexContainer>
            </Container>
        </FooterContainer>
    )
}

export default Footer
