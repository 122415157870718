import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import PropTypes from "prop-types"
import styled from "styled-components"

export const Label = styled.label`
    :hover {
        cursor: pointer;
    }
`

function SidebarLinks({ links, currentUuid, handleOnClick }) {
    return (
        <>
            {links.map((elm, index) => (
                <div
                    key={index + elm.value}
                    style={{
                        display: "flex",
                        marginBottom: "10px",
                    }}
                >
                    <Label htmlFor="html">
                        <span
                            style={{
                                fontFamily: "Montserrat",
                                fontSize: "18px",
                                textDecoration: "none",
                                display: "flex",
                                color:
                                    currentUuid === elm.value
                                        ? "rgb(222, 51, 42)"
                                        : "black",
                            }}
                            onClick={() => handleOnClick(elm.value)}
                        >
                            {elm.label}
                            <KeyboardArrowRightIcon />
                        </span>
                    </Label>
                    <br />
                </div>
            ))}
        </>
    )
}

export default SidebarLinks

SidebarLinks.propTypes = {
    links: PropTypes.array,
    currentUuid: PropTypes.any,
    handleOnClick: PropTypes.func,
}
