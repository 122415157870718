import Navigation from "./Navigation"
import DocumentProvider from "./services/providers/DocumentProvider"

function App() {
    return (
        <DocumentProvider>
            <Navigation />
        </DocumentProvider>
    )
}

export default App
