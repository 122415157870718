import styled from "styled-components"

export const FooterContainer = styled.div`
    background-color: black;
    color: gray;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    padding: 20px 0 0;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 6rem;
    @media (max-width: 600px) {
        height: 15rem;
    }
`

export const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
    * {
        color: white !important;
        margin: 0;
    }
    @media (max-width: 600px) {
        flex-direction: column;
        justify-content: space-around;
    }
`

export const Image = styled.img`
    width: 100px;
    height: auto;
    padding: 0 0 20px;
    margin: 0;
`

export const Copyright = styled.p`
    font-family: Montserrat;
    font-size: 14px;
    text-decoration: none;
    display: flex;
`

export const List = styled.ul`
    li {
        padding-bottom: 10px;
    }
`

export const LeftContainer = styled.div`
    @media (max-width: 600px) {
        margin-left: 20px;
    }
`
