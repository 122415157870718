import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    line-height: 24px;
`

export const Section = styled.div`
    margin-top: 30px;
`

export const SubTitle = styled.h3`
    color: rgb(222, 51, 42);
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 20px;
    font-family: Montserratmedium;
`

export const BlackSubTitle = styled.h4`
    font-style: italic;
    font-size: 18px;
    font-family: Montserratmedium;
`

export const RedSpan = styled.span`
    color: rgb(222, 51, 42);
`

export const DateSpan = styled.span`
    font-size: 18px;
`
